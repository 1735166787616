var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-12" }, [
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-header" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "form-group col-12 text-right" },
              [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: { name: "singleConsole", params: { id: "new" } },
                    },
                  },
                  [
                    _c(
                      "BaseButton",
                      { attrs: { type: "green", gradient: "" } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("addNewItem", { item: _vm.$tc("console") })
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "row justify-content-between align-items-end" },
            [
              _c(
                "div",
                { staticClass: "col-md-2" },
                [
                  _c("label", { staticClass: "control-label" }, [
                    _vm._v(
                      _vm._s(_vm.$t("filterBy", { item: _vm.$tc("category") }))
                    ),
                  ]),
                  _c(
                    "BaseDropdown",
                    {
                      attrs: {
                        "title-classes": "btn btn-secondary",
                        title: _vm.activeCategoryTitle,
                      },
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "dropdown-item",
                          on: {
                            click: function ($event) {
                              return _vm.filterByCategory("")
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("allCategories")))]
                      ),
                      _vm._l(_vm.categories, function (category) {
                        return _c(
                          "a",
                          {
                            key: category + "filter" + "category",
                            staticClass: "dropdown-item",
                            on: {
                              click: function ($event) {
                                return _vm.filterByCategory(category.alias)
                              },
                            },
                          },
                          [_vm._v(_vm._s(category.name))]
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-md-2" },
                [
                  _c("label", { staticClass: "control-label" }, [
                    _vm._v(
                      _vm._s(_vm.$t("filterBy", { item: _vm.$tc("type") }))
                    ),
                  ]),
                  _c(
                    "BaseDropdown",
                    {
                      attrs: {
                        "title-classes": "btn btn-secondary",
                        title: _vm.activeTypeTitle,
                      },
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "dropdown-item",
                          on: {
                            click: function ($event) {
                              return _vm.filterByType("")
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("allTypes")))]
                      ),
                      _vm._l(_vm.types, function (type) {
                        return _c(
                          "a",
                          {
                            key: type + "filter" + "type",
                            staticClass: "dropdown-item",
                            on: {
                              click: function ($event) {
                                return _vm.filterByType(type)
                              },
                            },
                          },
                          [_vm._v(_vm._s(type))]
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-md-2" },
                [
                  _c("label", { staticClass: "control-label" }, [
                    _vm._v(
                      _vm._s(_vm.$t("filterBy", { item: _vm.$tc("brand") }))
                    ),
                  ]),
                  _c(
                    "BaseDropdown",
                    {
                      attrs: {
                        "title-classes": "btn btn-secondary",
                        title: _vm.activeBrandTitle,
                      },
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "dropdown-item",
                          on: {
                            click: function ($event) {
                              return _vm.filterByBrand("")
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("allBrands")))]
                      ),
                      _vm._l(_vm.brands, function (brand) {
                        return _c(
                          "a",
                          {
                            key: brand.id + "filter" + "brand",
                            staticClass: "dropdown-item",
                            on: {
                              click: function ($event) {
                                return _vm.filterByBrand(brand)
                              },
                            },
                          },
                          [_vm._v(_vm._s(brand.name))]
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-md-2" },
                [
                  _c("label", { staticClass: "control-label" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("filterBy", { item: _vm.$tc("generation") })
                      )
                    ),
                  ]),
                  _c(
                    "BaseDropdown",
                    {
                      attrs: {
                        "title-classes": "btn btn-secondary",
                        title: _vm.activeGenerationTitle,
                      },
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "dropdown-item",
                          on: {
                            click: function ($event) {
                              return _vm.filterByGeneration("")
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("allGenerations")))]
                      ),
                      _vm._l(_vm.generations, function (generation) {
                        return _c(
                          "a",
                          {
                            key: generation.name + "filter" + "generation",
                            staticClass: "dropdown-item",
                            on: {
                              click: function ($event) {
                                return _vm.filterByGeneration(generation)
                              },
                            },
                          },
                          [_vm._v(_vm._s(generation.name))]
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-md-2" },
                [
                  _c("label", { staticClass: "control-label" }, [
                    _vm._v(
                      _vm._s(_vm.$t("filterBy", { item: _vm.$tc("medium", 2) }))
                    ),
                  ]),
                  _c(
                    "BaseDropdown",
                    {
                      attrs: {
                        "title-classes": "btn btn-secondary",
                        title: _vm.activeMediumTitle,
                      },
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "dropdown-item",
                          on: {
                            click: function ($event) {
                              return _vm.filterByMedium("")
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("allMedia")))]
                      ),
                      _vm._l(_vm.media, function (medium) {
                        return _c(
                          "a",
                          {
                            key: medium.name + "filter" + "medium",
                            staticClass: "dropdown-item",
                            on: {
                              click: function ($event) {
                                return _vm.filterByMedium(medium)
                              },
                            },
                          },
                          [_vm._v(_vm._s(medium.name))]
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-md-2" },
                [
                  _c("BaseInput", {
                    staticClass: "search",
                    attrs: { placeholder: _vm.$t("search") },
                    on: {
                      keyup: function ($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.lazyType(
                          _vm.filterBySearch,
                          _vm.lazyTypeDelay
                        )
                      },
                    },
                    model: {
                      value: _vm.keyword,
                      callback: function ($$v) {
                        _vm.keyword = $$v
                      },
                      expression: "keyword",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ]),
        _c(
          "div",
          { staticClass: "card-body" },
          [
            _c("BaseListTable", {
              attrs: {
                items: _vm.items,
                "total-items": _vm.totalItems,
                limit: _vm.limit,
                offset: _vm.offset,
                "header-fields": _vm.headerFields,
                type: "consoles",
                "has-pagination": "",
              },
              on: {
                updatePagination: _vm.updatePagination,
                delete: _vm.remove,
              },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }